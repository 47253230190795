


















import Vue from 'vue';

const CALLOUT_CONFIG = 'calloutConfig';

interface ICalloutContent {
  title: string,
  description: string,
}

export default Vue.extend({
  name: 'custom-callout',
  props: {
    id: {
      type: String,
      required: true,
    },
    content: {
      type: Object as () => ICalloutContent,
      required: true,
    },
    top: {
      type: Boolean,
      required: false,
    },
    left: {
      type: Boolean,
      required: false,
    },
    right: {
      type: Boolean,
      required: false,
    },
    offsetX: {
      type: Number,
      required: false,
    },
    offsetY: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      calloutConfig: [] as String[],
      position: { x: '0', y: '0' },
    };
  },
  computed: {
    user(): any {
      return this.$store.getters.user;
    },
    showCallout(): boolean {
      // To enable callout uncomment below line
      // return !this.calloutConfig.some(config => config === this.id);
      return false;
    },
    direction(): string {
      return this.top ? 'top' : this.left ? 'left' : this.right ? 'right' : 'bottom';
    },
    styleProps(): object {
      return {
        '--offset-x': this.position.x,
        '--offset-y': this.position.y,
      };
    },
  },
  watch: {
    calloutConfig(newValue) {
      localStorage.setItem(`${this.user.id}_${CALLOUT_CONFIG}`, JSON.stringify(newValue));
    },
  },
  created() {
    const config = localStorage.getItem(`${this.user.id}_${CALLOUT_CONFIG}`);
    this.calloutConfig = config ? JSON.parse(config) : [];
  },
  mounted() {
    this.calculatePosition();
  },
  methods: {
    onCloseCallout() {
      this.calloutConfig.push(this.id);
    },
    calculatePosition() {
      this.$nextTick(() => {
        let positionX = this.offsetX || 0;
        let positionY = this.offsetY || 0;
        const element = this.$refs.calloutWrapper as HTMLElement;
        if (this.top) {
          positionX += (element?.clientWidth || 0) / 2;
          positionY += (element?.clientHeight || 0);
        } else if (this.left) {
          positionX += 0;
          positionY += (element?.clientHeight || 0) / 2;
        } else if (this.right) {
          positionX += (element?.clientWidth || 0);
          positionY += (element?.clientHeight || 0) / 2;
        } else {
          positionX += (element?.clientWidth || 0) / 2;
          positionY += 0;
        }
        this.position = { x: `${positionX}px`, y: `${positionY}px` };
      });
    },
  },
});
