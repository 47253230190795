var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "calloutWrapper", staticClass: "d-flex" },
    [
      _vm._t("default", null, { closeFn: _vm.onCloseCallout }),
      _vm.showCallout
        ? _c(
            "div",
            {
              staticClass: "custom-callout",
              class: _vm.direction,
              style: _vm.styleProps
            },
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "resize",
                      rawName: "v-resize",
                      value: _vm.calculatePosition,
                      expression: "calculatePosition"
                    }
                  ],
                  attrs: { flat: "" }
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pa-2", on: { click: _vm.onCloseCallout } },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "starling-instructional mb-2",
                          staticStyle: { "padding-right": "30px" }
                        },
                        [
                          _c("strong", {
                            domProps: { innerHTML: _vm._s(_vm.content.title) }
                          })
                        ]
                      ),
                      _c("p", {
                        staticClass: "starling-text",
                        domProps: { innerHTML: _vm._s(_vm.content.description) }
                      }),
                      _c(
                        "v-btn",
                        {
                          staticStyle: {
                            "margin-right": "-12px",
                            "margin-top": "25px"
                          },
                          attrs: {
                            absolute: "",
                            light: "",
                            fab: "",
                            top: "",
                            right: "",
                            flat: "",
                            small: ""
                          }
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }